import SecurityHelper from '../helpers/SecurityHelper';
import { Role } from './roles';

export type MenuItemType = {
    key: string;
    label: string;
    labelKey?: string;
    isTitle?: boolean;
    icon?: string;
    url?: string;
    badge?: {
        variant: string;
        text: string;
    };
    parentKey?: string;
    target?: string;
    children?: MenuItemType[];
    availableIf?: Function;
};

const MENU_ITEMS: MenuItemType[] = [
    {
        key: 'dashboard',
        label: 'Dashboard',
        labelKey: 'sidebar.dashboard',
        url: '/',
        icon: 'mdi mdi-home',
    },
    {
        key: 'checkIn',
        label: 'Check-In',
        labelKey: 'sidebar.checkIn',
        url: '/check-in',
        icon: 'mdi mdi-qrcode-scan',
        availableIf: () => SecurityHelper.hasRole(Role.ROLE_CHECK_IN),
    },
    {
        key: 'crews',
        label: 'Crews',
        labelKey: 'sidebar.crews',
        url: '/crews',
        icon: 'mdi mdi-account-group',
        availableIf: () => SecurityHelper.hasRole(Role.ROLE_PLENUM),
    },
    {
        key: 'meta-crews',
        label: 'Meta Crews',
        labelKey: 'sidebar.metaCrews',
        url: '/meta-crews',
        icon: 'mdi mdi-lightbulb-group',
        availableIf: () => SecurityHelper.hasRole(Role.ROLE_PLENUM),
    },
    {
        key: 'persons',
        label: 'Personen',
        labelKey: 'sidebar.persons',
        url: '/persons',
        icon: 'mdi mdi-account-multiple',
        availableIf: () => SecurityHelper.hasRole(Role.ROLE_CREW_CARE_ADMIN),
    },
    {
        key: 'stewards',
        label: 'Ordner:innen',
        labelKey: 'sidebar.stewardManagement',
        url: '/steward-list',
        icon: 'mdi mdi-shield-account',
        availableIf: () => SecurityHelper.hasAnyRole([Role.ROLE_CREW_CARE_ADMIN, Role.ROLE_ADDON_STEWARD_MANAGER]),
    },
    {
        key: 'supporterDemands',
        label: 'Supporter:innen Bedarfe',
        labelKey: 'sidebar.supporterDemands',
        url: '/supporter-demands',
        icon: 'mdi mdi-shield-account',
        availableIf: () => SecurityHelper.hasRole(Role.ROLE_SUPPORTER_DEMAND),
    },
    {
        key: 'artistApplications',
        label: 'Bewerbungen Künstler:innen',
        labelKey: 'sidebar.artistApplications',
        url: '/artist-applications',
        icon: 'mdi mdi-account-music',
        availableIf: () =>
            SecurityHelper.hasRole(Role.ROLE_ARTIST_APPLICATIONS_ADMIN) ||
            SecurityHelper.hasArtistApplicationCategories(),
    },
    {
        key: 'kitchenMonitor',
        label: 'Küchen-Monitor',
        labelKey: 'sidebar.kitchenMonitor',
        url: '/kitchen-monitor',
        icon: 'mdi mdi-chart-line-stacked',
        availableIf: () => SecurityHelper.hasAnyRole([Role.ROLE_CREW_CARE_ADMIN, Role.ROLE_ADDON_KITCHEN_MONITOR]),
    },
    {
        key: 'crewAttendanceOverview',
        label: 'Crew-Anwesenheiten',
        labelKey: 'sidebar.crewAttendanceOverview',
        url: '/crew-attendance-overview',
        icon: 'mdi mdi-chart-gantt',
        availableIf: () =>
            SecurityHelper.hasAnyRole([Role.ROLE_CREW_CARE_ADMIN, Role.ROLE_ADDON_CREW_ATTENDANCE_OVERVIEW]),
    },
    {
        key: 'accountings',
        label: 'Abrechnungen',
        labelKey: 'sidebar.accountings',
        url: '/accountings',
        icon: 'mdi mdi-account-cash',
        availableIf: () => SecurityHelper.hasAnyRole([Role.ROLE_CASH_ASSISTANT]),
    },
    {
        key: 'projects',
        label: 'Veranstaltungen',
        labelKey: 'sidebar.projects',
        url: '/projects',
        icon: 'mdi mdi-rocket-launch',
        availableIf: () => SecurityHelper.hasRole(Role.ROLE_ADMIN),
    },
    {
        key: 'settings',
        label: 'Einstellungen',
        labelKey: 'sidebar.settings.title',
        isTitle: false,
        icon: 'uil-sliders-v',
        availableIf: () => SecurityHelper.hasRole(Role.ROLE_ADMIN),
        children: [
            {
                key: 'crew-types',
                label: 'Crew-Typen',
                labelKey: 'sidebar.settings.crewTypes',
                url: '/settings/crew-types',
                parentKey: 'settings',
                icon: 'uil-location',
                availableIf: () => SecurityHelper.hasRole(Role.ROLE_ADMIN),
            },
        ],
    },
];

export { MENU_ITEMS };
