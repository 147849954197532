import { store } from '../redux/store';
import { Role, roleHierarchies } from '../appConstants';

class SecurityHelper {
    static hasRole = (role: Role) => {
        const userRoles = store.getState().auth?.roles;
        if (!!userRoles?.find((ur: string) => Role[ur] === role)) return true;
        for (const roleHierarchy of roleHierarchies) {
            const roleIndex = roleHierarchy.indexOf(role);
            if (
                !!userRoles?.find((ur: string) =>
                    roleIndex !== -1 ? roleHierarchy.indexOf(Role[ur]) >= roleIndex : Role[ur] === role
                )
            )
                return true;
        }
        return false;
    };

    static hasAnyRole = (roles: Role[]) => {
        return !!roles.find((role: Role) => SecurityHelper.hasRole(role));
    };

    static isCrewMemberOrHigher = (crewId: string, otherAllowedRoles?: Role[]) => {
        const user = store.getState().auth;
        if (this.hasRole(Role.ROLE_CREW_CARE_ADMIN)) return true;
        if (otherAllowedRoles) {
            if (otherAllowedRoles.find((allowedRole: Role) => SecurityHelper.hasRole(allowedRole))) {
                return true;
            }
        }
        return (
            user.memberOfCrews?.includes(crewId) ||
            user.managerOfCrews?.includes(crewId) ||
            user.adminOfCrews?.includes(crewId)
        );
    };

    static isCrewManagerOrHigher = (crewId: string, otherAllowedRoles?: Role[]) => {
        const user = store.getState().auth;
        if (this.hasRole(Role.ROLE_CREW_CARE_ADMIN)) return true;
        if (otherAllowedRoles?.find((allowedRole: Role) => SecurityHelper.hasRole(allowedRole))) {
            return true;
        }
        return user.managerOfCrews?.includes(crewId) || user.adminOfCrews?.includes(crewId);
    };

    static isCrewAdmin = (crewId: string, otherAllowedRoles?: Role[]) => {
        const user = store.getState().auth;
        if (this.hasRole(Role.ROLE_CREW_CARE_ADMIN)) return true;
        if (otherAllowedRoles?.find((allowedRole: Role) => SecurityHelper.hasRole(allowedRole))) {
            return true;
        }
        return user.adminOfCrews?.includes(crewId);
    };

    static isCrewAdminOfAnyCrew = (crewIds: string[], otherAllowedRoles?: Role[]) => {
        const user = store.getState().auth;
        if (this.hasRole(Role.ROLE_CREW_CARE_ADMIN)) return true;
        if (otherAllowedRoles?.find((allowedRole: Role) => SecurityHelper.hasRole(allowedRole))) {
            return true;
        }
        return crewIds.some((crewId) => user.adminOfCrews?.includes(crewId));
    };

    static hasArtistApplicationCategories = () => {
        const user = store.getState().auth;
        return user.hasArtistApplicationCategories;
    };
}

export default SecurityHelper;
